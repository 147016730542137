<template>
  <div>
    <div ref="container">
      <a-affix :offset-top="90">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240); background: #fff"
          class="mb-5 pr-0"
          title="Add Voucher"
          @back="() => $router.push({ name: 'promotion-channel.web-voucher', query: $route.query })"
        />
      </a-affix>
    </div>
    <VoucherForm />
  </div>
</template>

<script>
import VoucherForm from './components/Form'

export default {
  components: {
    VoucherForm,
  },
}
</script>
